<template>
  <div id="app">
    <h1>Portland OpenPath</h1>
    <div class="header-buttons">
      <!--<a class="top-button" href="#" target="_blank">Apply Now</a>-->
    </div>
    <img alt="Portland OpenPath logo" class="pop-header-horizontal" src="./assets/pop-header-horizontal.svg"/>
    <img alt="Portland OpenPath logo" class="pop-header-vertical" src="./assets/pop-header-vertical.svg"/>
    <img alt="A collage of cascading mountains with buildings dappled between the ridges" class="pop-header-bg" src="./assets/pop-hero@2x.png"/>
    <h2>About Portland OpenPath</h2>
    <p>Finding your way into any industry can be difficult. With tuition costs constantly rising, the traditional method of enrolling in a 4-year institution to obtain a degree is time-consuming and expensive. For members of overlooked communities, trying to break into an new industry can be even more burdensome when it appears to lack diversity. <b>Portland OpenPath is a community collective with the goal of bringing diverse voices and talent into the tech and advertising industries of Portland.</b> Portland OpenPath seeks to provide alternative educational opportunities to individuals who identify with an overlooked community: Black, Indigenous, Hispanic/Latinx, LGBTQ, nonbinary and gender nonconforming folks, and women. With a workforce that reflects the entire Portland community, not only do we support equity and justice, but we create products and services that cater to us all.</p>
    <h2>How can Portland OpenPath help me?</h2>
    <p>Portland OpenPath offers grants to bootcamp programs as well as professional development assistance including but not limited to: resume reviews, portfolio reviews, informational interviews, interview practice, and networking opportunities.</p>
    <h2>Can I apply for a grant?</h2>
    <p>The Portland OpenPath Grant is for Black, Indigenous, Hispanic/Latinx, LGBTQ+, nonbinary and gender nonconforming folks, women, and other members of overlooked communities who have interest in technology and advertising but do not have the financial means to pursue a formal education. Portland OpenPath is fully funding a part-time programming course at Epicodus for one person. The Part-Time Full-Stack session starts in March of 2022. The application deadline is January 15th, 2022. After the deadline closes, applicants will be contacted to schedule an interview. Decisions will be announced by February 4th, 2022.</p>
    <div class="application-cta">
      <a class="cta-button" href="https://forms.gle/bHq2TXa23tJwsH8q8" target="_blank">Apply for PDX OpenPath 2022</a>
    </div>
    <h2>Partners</h2>
    <div class="partners">
      <a class="partner" href="https://www.epicodus.com/">
        <img src="./assets/partner-epicodus.png" alt="Epicodus logo" target="_blank"/>
      </a>
      <a class="partner" href="https://www.pollinate.com/">
        <img src="./assets/partner-pollinate.png" alt="Pollinate logo" target="_blank"/>
      </a>
      <a class="partner" href="https://www.spectrumcustomizer.com/">
        <img src="./assets/partner-spectrum.png" alt="Spectrum Customizer logo" target="_blank"/>
      </a>
      <a class="partner" href="https://prosperportland.us/">
        <img src="./assets/partner-prosper-portland.png" alt="Prosper Portland logo" target="_blank"/>
      </a>
      <a class="partner" href="https://techtownportland.com/">
        <img src="./assets/partner-techtown-pdx.png" alt="Techtown Portland logo" target="_blank"/>
      </a>
        <a class="partner" href="https://www.learning.com/">
        <img src="./assets/partner-learning.png" alt="Learning.com logo" target="_blank"/>
      </a>
    </div>
    <h2>Frequently asked questions</h2>
    <p>
      <i>What is an overlooked community?</i>
      <br/>
      We use the word overlooked instead of underrepresented because Black, Indigenous, Hispanic/Latinx, LGBTQ, nonbinary and gender nonconforming folks, and women are not living under a rock. The word underrepresented makes exclusion sound accidental rather than intentional.
      <br/><br/>
      <i>I am interested but do not have access to a computer with internet access. Will Portland OpenPath be able to provide me with a computer?</i>
      <br/>
      We do not have the funding allocated to purchase a computer for OpenPath grant recipients. However, do not let this hold you back from applying. Career Karma has an initiative called <a href="https://careerkarma.com/reskill-america/" target="_blank">Reskill America</a> that is providing laptops to communities that need them at this time. We will do everything we can to help you obtain a computer suitable for your coursework.
      <br/><br/>
      <i>If I am the recipient of the Portland OpenPath fellowship will I be guaranteed work after I have finished the course?</i>
      <br/>
      There is no guarantee but the part-time full development course at Epicodus does include an internship. Not all companies hire interns straight out of the program but some of them may elect to. The internship is an invaluable experience that can really strengthen your resume.
      <br/><br/>
      <i>Is Portland OpenPath aware that there are plenty of qualified individuals out there who would be able to fill these positions at local tech and advertising companies?</i>
      <br/>
      Yes. We are aware that some of the issues that we are trying to address cannot be boiled down to a lack of access alone. Problematic hiring processes and company culture are often barriers to entry. There are plenty of qualified individuals out there who would make excellent additions to our local industry. However, there are opportunities needed specifically in the Portland community and we are hoping to create these opportunities. Additionally, we would like to help normalize the idea that alternatives to traditional educational institutions are a more sustainable option for continued industry growth.
    </p>
    <h2>Join us</h2>
    <p>
      We would like to continue to cultivate Portland OpenPath. If you are interested in joining our collective or if you would like to help with funding, please contact us at <a href="mailto:pdxopenpath@gmail.com">pdxopenpath@gmail.com</a>. We hope that the Portland technology and advertising communities will join us in creating a more sustainable path into advertising and software development for unheard voices in Portland.
    </p>
    <div class="footer">
      <div class="footer-logo">
        <img alt="Portland OpenPath logo" src="./assets/pop-header-vertical.svg"/>
      </div>
      <div class="footer-hello">
        <div>
          Say hello @pdxopenpath
          <br/>
          Or at <a href="mailto:pdxopenpath@gmail.com">pdxopenpath@gmail.com</a>
        </div>
      </div>
      <div class="footer-icons">
        <a href="https://www.facebook.com/pdxopenpath" alt="Link to Facebook account" target="_blank">
          <div>
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.98642 152.9864">
              <circle cx="76.4932" cy="76.4932" r="76.4932" fill="#2c2f2b"/>
              <path d="M93.083,76.5196H81.1133v42.6767H63.37412V76.5196H54.9405V61.4453h8.43362V51.6875c0-6.9746,3.31048-17.8984,17.89838-17.8984l13.14162.0557V48.4776H84.8789c-1.5664,0-3.7656.7802-3.7656,4.1074v8.873H94.6289Z" fill="#fff"/>
            </svg>
          </div>
        </a>
        <a href="https://www.instagram.com/pdxopenpath/" alt="Link to Instagram account" target="_blank">
          <div>
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.9853 152.9795">
              <path d="M76.4971,152.9795a76.48975,76.48975,0,1,1,76.4882-76.4893A76.58015,76.58015,0,0,1,76.4971,152.9795Z" fill="#2c2f2b"/>
              <path d="M96.35352,111.9179H58.6396A15.62352,15.62352,0,0,1,43.0381,96.3086V58.6025A15.624,15.624,0,0,1,58.6396,42.9941H96.35352A15.63143,15.63143,0,0,1,111.9629,58.6025V96.3086A15.63091,15.63091,0,0,1,96.35352,111.9179ZM58.6396,49.8584a8.754,8.754,0,0,0-8.74308,8.7441V96.3086a8.75485,8.75485,0,0,0,8.74308,8.7509H96.35352a8.75559,8.75559,0,0,0,8.74318-8.7509V58.6025a8.75479,8.75479,0,0,0-8.74318-8.7441ZM77.5,95.4716A17.812,17.812,0,1,1,95.31252,77.6591,17.83207,17.83207,0,0,1,77.5,95.4716Zm0-28.7666A10.9541,10.9541,0,1,0,88.4551,77.6591,10.96723,10.96723,0,0,0,77.5,66.705ZM95.9902,55.4648a4.522,4.522,0,1,0,4.5274,4.5264A4.51681,4.51681,0,0,0,95.9902,55.4648Z" fill="#fff"/>
            </svg>
          </div>
        </a>
        <!--
        <a href="#" alt="Link to LinkedIn account">
          <div>
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.9863 152.9864">
              <path d="M76.49711,152.9864A76.4932,76.4932,0,1,1,152.9863,76.4932,76.58226,76.58226,0,0,1,76.49711,152.9864Z" fill="#2c2f2b"/>
              <path d="M120.2158,77.9864v28.7363H103.5586V79.9141c0-6.7324-2.4062-11.3311-8.4346-11.3311a9.11936,9.11936,0,0,0-8.5478,6.0918,11.43289,11.43289,0,0,0-.5508,4.0625v27.9854H69.3604s.2265-45.4082,0-50.1094h16.665v7.0996c-.03029.0567-.083.1094-.11329.166h.11329v-.166c2.21871-3.4072,6.1631-8.2754,15.01371-8.2754,10.96089,0,19.17669,7.1592,19.17669,22.5489ZM51.9336,32.4571c-5.7041,0-9.4307,3.7421-9.4307,8.6572,0,4.8096,3.6221,8.6601,9.212,8.6601h.1054c5.8164,0,9.4229-3.8505,9.4229-8.6601-.1055-4.9151-3.6065-8.6572-9.3096-8.6572Zm-8.4434,74.2656H60.1494V56.6133H43.4902Z" fill="#fff"/>
            </svg>
          </div>
        </a>
        -->
        <a href="https://twitter.com/pdxopenpath" alt="Link to Twitter account" target="_blank">
          <div>
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.9912 152.9824">
              <path d="M76.4932,152.9824a76.4912,76.4912,0,1,1,76.498-76.4883A76.57724,76.57724,0,0,1,76.4932,152.9824Z" fill="#2c2f2b"/>
              <path d="M122.1963,52.126a34.71569,34.71569,0,0,1-10.0117,2.7431,17.45208,17.45208,0,0,0,7.6582-9.6455,34.69737,34.69737,0,0,1-11.0605,4.2315,17.44754,17.44754,0,0,0-30.169,11.9277,17.64761,17.64761,0,0,0,.4541,3.9766A49.5102,49.5102,0,0,1,43.1328,47.1397,17.45208,17.45208,0,0,0,48.5283,70.417a17.33714,17.33714,0,0,1-7.8964-2.1807c-.003.0713-.003.1475-.003.2188A17.44636,17.44636,0,0,0,54.6162,85.5508a17.37512,17.37512,0,0,1-4.5957.6142,17.91489,17.91489,0,0,1-3.2822-.3115A17.4568,17.4568,0,0,0,63.0274,97.958a35.18954,35.18954,0,0,1-25.8086,7.2197,49.31053,49.31053,0,0,0,26.7207,7.8389c32.0713,0,49.6035-26.5683,49.6035-49.6113,0-.7549-.0147-1.5088-.0449-2.2559a35.331,35.331,0,0,0,8.6982-9.0234Z" fill="#fff"/>
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;900&display=swap');

// Breakpoints
$xl-up:       "min-width: 1500px";  // Desktop
$xl-down:     "max-width: 1499px";
$lg-up:       "min-width: 1200px";  // Desktop
$lg-down:     "max-width: 1199px";
$md-up:       "min-width: 1024px";  // Tablet
$md-down:     "max-width: 1023px";
$sm-up:       "min-width: 768px";   // Phablet
$sm-down:     "max-width: 767px";
$xs-up:       "min-width: 480px";   // Phone
$xs-down:     "max-width: 479px";
$micro-up:    "min-width: 321px";   // Micro
$micro-down:  "max-width: 320px";

body {
  background-color: #FFFBF3;
}

#app {
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  color: #2C2F2A;
  max-width: 1440px;
  margin: auto;
  margin-top: 95px;
  position: relative;
  @media($lg-down) {
    margin-top: 20px;
  }
}

h1 {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px,1px,1px,1px);
}

a:focus {
  outline: 3px solid #51B1B2;
  outline-offset: 2px;
}

.pop-header-horizontal {
  max-width: 775px;
  z-index: 5;
  position: relative;
  @media ($lg-down) {
    display: none;
  }
}

.pop-header-vertical {
  max-width: 475px;
  z-index: 5;
  position: relative;
  display: none;
  @media ($lg-down) {
    display: inline;
  }
  @media ($sm-down) {
    width: 50vw;
  }
}

.pop-header-bg {
  z-index: 0;
  max-width: 100%;
  position: relative;
  top: -35px;
  @media ($sm-down) {
    top: -5vw;
    max-width: none;
    max-height: 310px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
}

a {
  font-family: 'Work Sans', sans-serif;
  text-transform: uppercase;
  border-radius: 0;
  border: none;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
  transition-property: background, color;
  transition-duration: 0.25s;
}

.header-buttons {
  width: 100%;
  position: absolute;
  top: -40px;
  text-align: right;

  @media ($lg-down) {
    top: 40px;
  }

  @media ($md-down) {
    display: none;
  }
}

.top-button {
  display: inline-block;
  position: relative;
  padding: 9px 16px;
  background: transparent;
  border: 1px solid #535652;
  color: #535652;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 1px;
  margin-right: 50px;

  &:hover {
    background: #535652;
    color: #FFFBF3;
  }
}

.cta-button {
  background: #2C2F2A;
  color: #FFFFFF;
  font-size: 29px;
  letter-spacing: 1px;
  padding: 35px 70px;
  &:hover {
    background: #535652;
  }
  @media ($sm-down) {
    font-size: 16px;
    padding: 20px 40px;
  }
}

h2 {
  font-size: 27px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 120px;
  letter-spacing: 2px;
  @media ($lg-down) {
    padding-left: 20px;
  }
  @media ($sm-down) {
    font-size: 16px;
  }
}

p {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  max-width: 960px;
  text-align: left;
  margin: auto;
  margin-bottom: 100px;
  @media ($lg-down) {
    padding: 0 20px;
  }
  @media ($sm-down) {
    font-size: 16px;
    margin-bottom: 50px;
    font-weight: 400;
  }
  b {
    font-weight: 500;
  }
  i {
    font-weight: 500;
  }
  a {
    font-weight: 500;
    text-transform: none;
    color: #2C2F2A;
    &:visited, &:active {
      color: #2C2F2A;
    }
    &:hover {
      color: #535652;
    }
  }
}

.application-cta {
  max-width: 1234px;
  height: 334px;
  background-image: url('assets/pop-buttonimage@2x.jpg');
  background-size: cover;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  @media ($sm-down) {
    height: 200px;
    margin-bottom: 40px;
  }
}

.partners {
  display: flex;
  width: 100%;
  max-width: 1234px;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  .partner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 40px;
    @media ($sm-down) {
      width: 40%;  
    }
    img {
      width: 75%;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: auto;
  margin-bottom: 40px;
  @media (max-width: 850px) {
    flex-direction: column;
    div {
      margin: 10px 0;
    }
  }
  .footer-logo {
    position: relative;
    img {
      width: 200px;
      max-width: 200px;
      margin: 0 20px;
    }
  }
  .footer-hello {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      text-align: left;
    }
    a {
      text-transform: none;
      color: #2C2F2A;
      font-weight: 500;
    }
  }
  .footer-icons {
    a {
      color: #2C2F2A;
      div {
        display: inline-block;
        width: 70px;
        svg {
          width: 50px;
          height: auto;
          margin: 8px 0;
        }
      }
    }
  }
}

</style>
